
					$oemKey: moke;
					$oem-brand-color: #284A63;
					$oem-brand-color-light: #F9F3E7;
				
						$oem-font-family-stack: #{'Oswald', Sans-serif};
					
						$oem-font-family-url: "https://fonts.googleapis.com/css2?family=Oswald:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
					
/**
 *	Styles
 */
// airdealer component library import
@import 'node_modules/@ekhodealer/ekho-common/dist/scss/styles';

// @import 'settings/index';
// @import 'tools/index';
// @import 'generic/index';
// @import 'elements/index';
// @import 'objects/index';
@import 'components/index';
// @import 'vendors/index';
// @import 'utilities/index';
// @import 'node_modules/bootstrap/scss/maps';
// @import 'node_modules/bootstrap/scss/utilities/api';
// @import 'custom/index';

.pac-container {
	z-index: 1056;
}

@keyframes onAutoFillStart {
	from {
		/**/
	}
	to {
		/**/
	}
}

@keyframes onAutoFillCancel {
	from {
		/**/
	}
	to {
		/**/
	}
}

input:-webkit-autofill {
	/* Start the animation when autofill happens */
	animation-name: onAutoFillStart;
	animation-duration: 0.01s; /* Minimal duration to trigger the event */
	transition: background-color 50000s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
	// Expose a hook for JS onAutoFillCancel
	// JavaScript can capture 'animationstart' events
	animation-name: onAutoFillCancel;
	animation-duration: 0.01s; /* Minimal duration to trigger the event */
}

// &:autofill {
// 	// Expose a hook for JavaScript when auto fill is shown.
// 	// JavaScript can capture 'animationstart' events
// 	animation-name: onAutoFillStart;

// 	// Make the backgound color become yellow _really slowly_
// 	transition: background-color 50000s ease-in-out 0s;
// }

// &:not(:autofill) {
// 	// Expose a hook for JS onAutoFillCancel
// 	// JavaScript can capture 'animationstart' events
// 	animation-name: onAutoFillCancel;
// }
